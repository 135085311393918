import * as React from "react";
import { useState } from 'react';
import styled from "styled-components";
import Simple from "@templates/Simple";
import DataContenidos from "@data/DataContenidos";
import cookie from 'react-cookies'
import { Contenido00, Contenido10, Contenido11, Contenido12, Contenido20, Contenido21, Contenido22, Contenido23, Contenido24, Contenido30, Contenido31, Contenido32, Contenido33, Contenido34, Contenido40, Contenido41, Contenido42, Contenido43, Contenido44, Contenido50, Contenido51, Contenido52, Contenido53, Contenido54, Contenido60, Contenido61, Contenido62, Contenido63, Contenido64, Contenido70, Contenido71, Contenido72, Contenido73 } from "@components/contenidos/capitulos";

const Page = () => {

  const [menu, setmenu] = useState(cookie.load('cricontenidomenu')?cookie.load('cricontenidomenu'):"00");
  const [subMenu, setsubMenu] = useState(cookie.load('cricontenido')?cookie.load('cricontenido'):"00");



  return (
    <Simple title={"CRI: Contenidos"}>
      <Wrapper>
        <LeftNav>
          <h1
            onClick={() => {
              setmenu("00");
              setsubMenu("00");
              cookie.save("cricontenido", "00");
            }}
          >Contenidos</h1>
          {
            DataContenidos["data"] && DataContenidos["data"].length > 0
              ? DataContenidos["data"].map((item, index) =>
              (
                <div key={`leftnavMegaDiv${item.linkDir}${index}`}>
                  <h1
                    onClick={() => {
                      setmenu(item.linkDir);
                      cookie.save("cricontenidomenu", item.linkDir);
                      setsubMenu(item.linkDir);
                      cookie.save("cricontenido", item.linkDir);
                    }}
                    key={`leftnav${item.linkDir}${index}`}
                  >{item.title}</h1>
                  {
                    menu === item.linkDir
                      ? (
                        <div key={`leftnavdiv${item.linkDir}${index}`} className={"submenu"}>
                          {
                            DataContenidos["data"][index]["content"] && DataContenidos["data"][index]["content"].length > 0
                              ? DataContenidos["data"][index]["content"].map((subitem, subindex) =>
                              (
                                <h2
                                  onClick={() => {
                                    setsubMenu(subitem.linkDir);
                                    cookie.save("cricontenido", subitem.linkDir);
                                  }}
                                  key={`subleftnav${subitem.linkDir}${subindex}`}
                                >{subitem.title}</h2>
                              )
                              )
                              : null
                          }
                        </div>
                      )
                      : null
                  }
                </div>
              )
              )
              : null
          }

        </LeftNav>
        <ContentSpace>
          {
            subMenu === "00"
              ? <Contenido00 />
              : null
          }
          {
            subMenu === "10"
              ? <Contenido10 />
              : null
          }
          {
            subMenu === "11"
              ? <Contenido11 />
              : null
          }
          {
            subMenu === "12"
              ? <Contenido12 />
              : null
          }
          {
            subMenu === "20"
              ? <Contenido20 />
              : null
          }
          {
            subMenu === "21"
              ? <Contenido21 />
              : null
          }
          {
            subMenu === "22"
              ? <Contenido22 />
              : null
          }
          {
            subMenu === "23"
              ? <Contenido23 />
              : null
          }
          {
            subMenu === "24"
              ? <Contenido24 />
              : null
          }
          {
            subMenu === "30"
              ? <Contenido30 />
              : null
          }
          {
            subMenu === "31"
              ? <Contenido31 />
              : null
          }
          {
            subMenu === "32"
              ? <Contenido32 />
              : null
          }
          {
            subMenu === "33"
              ? <Contenido33 />
              : null
          }
          {
            subMenu === "34"
              ? <Contenido34 />
              : null
          }
          {
            subMenu === "40"
              ? <Contenido40 />
              : null
          }
          {
            subMenu === "41"
              ? <Contenido41 />
              : null
          }
          {
            subMenu === "42"
              ? <Contenido42 />
              : null
          }
          {
            subMenu === "43"
              ? <Contenido43 />
              : null
          }
          {
            subMenu === "44"
              ? <Contenido44 />
              : null
          }
          {
            subMenu === "50"
              ? <Contenido50 />
              : null
          }
          {
            subMenu === "51"
              ? <Contenido51 />
              : null
          }
          {
            subMenu === "52"
              ? <Contenido52 />
              : null
          }
          {
            subMenu === "53"
              ? <Contenido53 />
              : null
          }
          {
            subMenu === "54"
              ? <Contenido54 />
              : null
          }
          {
            subMenu === "60"
              ? <Contenido60 />
              : null
          }
          {
            subMenu === "61"
              ? <Contenido61 />
              : null
          }
          {
            subMenu === "62"
              ? <Contenido62 />
              : null
          }
          {
            subMenu === "63"
              ? <Contenido63 />
              : null
          }
          {
            subMenu === "64"
              ? <Contenido64 />
              : null
          }
          {
            subMenu === "70"
              ? <Contenido70 />
              : null
          }
          {
            subMenu === "71"
              ? <Contenido71 />
              : null
          }
          {
            subMenu === "72"
              ? <Contenido72 />
              : null
          }
          {
            subMenu === "73"
              ? <Contenido73 />
              : null
          }
        </ContentSpace>
      </Wrapper>
    </Simple>
  )
}

export default Page;







const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2em;


@media (min-width:768px){
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 2em 1em;
}

`;

const LeftNav = styled.div`
  border: solid 1px var(--lightGrey);
  width: 100%;
  padding: 1em;

  @media (min-width:768px){
    width: 30%;
    padding: 2em 1em;
  }

  h1{
    font-size: 1em;
    :hover{
      color: var(--color5);
    }
  }
  h2{
    font-size: 0.9em;
    padding: 0.5em 2em;
    border: 1px solid var(--color5);
    border-radius: 5px;
    :hover{
      color: var(--color5);
    }
  }

  h1,h2{
    cursor: pointer;
  }

  


`;

const ContentSpace = styled.div`
width: 100%;
padding: 1em;
img{ 
    width:100%;
}
  @media (min-width:768px){
    width: 70%;
    padding: 1em;
  }

h1{
  font-size:2em;
}
h2{
  font-size:1.5em;
}

p{
  text-align: justify;
}
ul{
  list-style:circle;
  list-style-type:circle;
}
`;