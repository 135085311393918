const DataContenidos = {
    "data": [
        {
            "title":"Módulo 1: Introducción",
            "linkDir":"10",
            "content":[
                {
                    "title":"Objetivos",
                    "linkDir":"11",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"12",
                }
            ]
        },
        {
            "title":"Módulo 2: Mala Conducta Científica",
            "linkDir":"20",
            "content":[
                {
                    "title":"Caso Introductorio",
                    "linkDir":"21",
                },
                {
                    "title":"Objetivos",
                    "linkDir":"22",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"23",
                },
                {
                    "title":"Caso de evaluación",
                    "linkDir":"24",
                }
            ]
        },
        {
            "title":"Módulo 3: Plagio",
            "linkDir":"30",
            "content":[
                {
                    "title":"Caso Introductorio",
                    "linkDir":"31",
                },
                {
                    "title":"Objetivos",
                    "linkDir":"32",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"33",
                },
                {
                    "title":"Caso de evaluación",
                    "linkDir":"34",
                }
            ]
        },
        {
            "title":"Módulo 4: Autoría Responsable",
            "linkDir":"40",
            "content":[
                {
                    "title":"Caso Introductorio",
                    "linkDir":"41",
                },
                {
                    "title":"Objetivos",
                    "linkDir":"42",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"43",
                },
                {
                    "title":"Caso de evaluación",
                    "linkDir":"44",
                }
            ]
        },
        {
            "title":"Módulo 5: Publicación Responsable",
            "linkDir":"50",
            "content":[
                {
                    "title":"Caso Introductorio",
                    "linkDir":"51",
                },
                {
                    "title":"Objetivos",
                    "linkDir":"52",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"53",
                },
                {
                    "title":"Caso de evaluación",
                    "linkDir":"54",
                }
            ]
        },
        {
            "title":"Módulo 6: Conflictos de Interés",
            "linkDir":"60",
            "content":[
                {
                    "title":"Caso Introductorio",
                    "linkDir":"61",
                },
                {
                    "title":"Objetivos",
                    "linkDir":"62",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"63",
                },
                {
                    "title":"Caso de evaluación",
                    "linkDir":"64",
                }
            ]
        },
        {
            "title":"Módulo 7: Mentoría",
            "linkDir":"70",
            "content":[
                {
                    "title":"Objetivos",
                    "linkDir":"71",
                },
                {
                    "title":"Videos",
                    "linkDir":"72",
                },
                {
                    "title":"Ver Clase",
                    "linkDir":"73",
                }
            ]
        },
    ]
}

export default DataContenidos;