import React, { useState } from 'react';
import contenidosImg from "@images/contenidos.jpg";
import styled from 'styled-components';
import modulo1 from "@images/modulo1.jpg";
import modulo2 from "@images/modulo2.jpg";
import modulo3 from "@images/modulo3.jpg";
import modulo4 from "@images/modulo4.jpg";
import modulo5 from "@images/modulo5.jpg";
import modulo6 from "@images/modulo6.jpg";
import modulo7 from "@images/modulo7.jpg";
import m2Img1 from "@images/M2_caso_introductorio.png";
import m2Img2 from "@images/M2_caso_introductorio2.png";
import m2Img3 from "@images/M2_caso_evaluacion.png";
import m3Img1 from "@images/M3_caso_introductorio.png";
import m3Img2 from "@images/M3_caso_evaluacion.png";
import m4Img1 from "@images/M4_caso_introductorio.png"
import m4Img2 from "@images/M4_caso_evaluacion.png"
import m5Img1 from "@images/M5_caso_introductorio.png"
import m5Img2 from "@images/M5_caso_evaluacion.png"
import m6Img1 from "@images/M6_caso_introductorio.png"
import m6Img2 from "@images/M6_caso_evaluacion.png"

export const CaseOption = styled.li`
  cursor: pointer;
  margin: 1em 0;
  padding: 1em;
  color: var(--color1);
  border: 1px solid var(--color1);
  border-radius: 1em;
  b{
    color: var(--color4);
  }
  p{
    color:black;
  }
  :hover{
    b{
      color: var(--color1);
    }
  }
`;

export const Contenido00 = () => {
  return (
    <>
      <h1>Contenidos</h1>
      <p>Este curso sobre Conducta Responsable en Investigación se divide en siete módulos que se vinculan unos con otros. Si bien es posible iniciar el curso por cualquiera de ellos, para una mejor comprensión de los contenidos se recomienda empezar por el primer módulo introductorio, continuar con el segundo y así sucesivamente, siguiendo la secuencia planteada.</p>
      <p>Los primeros seis módulos incluyen casos de estudio que servirán para motivar la reflexión sobre los temas tratados y para evaluar el aprendizaje en cada etapa, mientras que el último módulo presenta una serie de videos cortos que ayudarán a reforzar los contenidos.</p>
      <p>Finalmente, el curso ofrece también un test de evaluación integral de los conocimientos adquiridos en todos los módulos. Las personas que deseen obtener una certificación deberán aprobar ese test.</p>
      <img className={"big"} src={contenidosImg} alt={contenidosImg} />
    </>
  )
};

export const Contenido10 = () => {
  return (
    <>
      <h1>Módulo 1: Introducción a la Conducta Responsable en Investigación</h1>
      <p>Este módulo introductorio ofrece una visión de conjunto sobre los conceptos fundamentales de la integridad científica, la historia del desarrollo de sus principios y valores, y las prácticas que constituyen la conducta responsable en investigación (CRI).</p>
      <img className={"big"} src={modulo1} alt={modulo1} />
    </>
  )
};

export const Contenido11 = () => {
  return (
    <>
      <h1>Objetivos</h1>
      <ul>
        <li>Definir qué son la Integridad Científica y la Conducta Responsable en Investigación (CRI).</li>
        <li>Dar a conocer algunos aspectos históricos del desarrollo de estos temas.</li>
        <li>Presentar cuáles son las prácticas que caracterizan a la CRI.</li>
        <li>Identificar a los diversos actores que, en una institución determinada, pueden potencialmente contribuir con el desarrollo de conductas responsables en investigación.
        </li>
      </ul>
    </>
  )
};

export const Contenido12 = () => {
  return (
    <>
      <h1>Ver clase</h1>
      <div className={"ytvideo"}><iframe width="100%" height="100%" src="https://www.youtube.com/embed/hw9xDkmpL4s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    </>
  )
};

export const Contenido20 = () => {
  return (
    <>
      <h1>Módulo 2: Mala Conducta Científica</h1>
      <p>Para mantener y promover una conducta responsable en investigación es importante poder reconocer e identificar la mala conducta científica y las prácticas cuestionables en investigación. Este módulo discute estos conceptos y también, de manera general, con la ayuda de casos y ejemplos, aspectos como la fabricación de datos, la falsificación y el plagio, y brinda además pautas para manejar adecuadamente los casos de mala conducta científica.</p>
      <img className={"big"} src={modulo2} alt={modulo2} />
    </>
  )
};

export const Contenido21 = () => {
  return (
    <>
      <h1>El Legado de la Sentencia de Hwang: Mala Conducta Científica en Biociencias*</h1>
      <img className={"left"} src={m2Img1} alt={m2Img1} />
      <p>El Dr. Hwang Woo Suk, un profesor surcoreano de biotecnología y teriogenología (estudio de la reproducción en animales) de la Universidad Nacional de Seúl (UNS), era considerado un héroe nacional en su país y un pionero en la investigación con células madre. En el año 2006, un comité de la UNS confirmó los alegatos de fraude y mala conducta científica que pesaban sobre él y su equipo. A raíz del escándalo, eventualmente admitió haber cometido fraude en varias ocasiones, razón por la cual fue despedido de la UNS y procesado penalmente.</p>
      <p>
        Antes de eso, Hwang había alcanzado la cima del reconocimiento por dos artículos suyos publicados en los años 2004 y 2005 en la prestigiosa revista Science. En esos artículos –que fueron luego eliminados de la revista–, informaba haber creado células madre humanas embrionarias por clonación. Previamente, desde 1999, se había hecho famoso en su país luego de anunciar que había tenido éxito en la clonación de vacas lecheras, y que planeaba clonar un tigre siberiano. Desde entonces, los medios de comunicación no cesaron de destacar las grandes perspectivas económicas y médicas derivadas de su investigación.
      </p>
      <p>
        En su publicación del 2004 en Science, Hwang y su equipo afirmaban haber usado el método de la transferencia nuclear de células somáticas, utilizando 242 óvulos para crear un solo linaje de células, lo cual fue presentado como el primer éxito reportado de clonación de células somáticas humanas y como un avance revolucionario en biotecnológica. Poco después, en el 2005, anunciaron en la misma revista haber obtenido una tasa de éxito 14 veces mayor, comparada con los resultados previos, al crear, a partir de 185 óvulos, 11 células madre embrionarias humanas, lo cual permitiría crear materiales biológicos y tratamientos hechos a la medida sin reacciones inmunes en los pacientes, además de células madre que harían posible modelar enfermedades humanas.
      </p>
      <p>
        Antes de que estallara el escándalo, un colega estadounidense de Hwang, Gerald Schatten, manifestó que había decidido poner fin a la colaboración que mantuvo por casi dos años con el científico coreano, debido a controversias relacionadas con la investigación publicada en el 2004, y solicitó a Science que retirara su nombre del citado artículo. Este deslinde motivó que otros investigadores examinaran a fondo la validez científica del trabajo de Hwang.
      </p>
      <p>
        La primera falta ética discutida se relacionaba con la donación de óvulos. Un colaborador de Hwang admitió que se había pagado 1 400 dólares a las mujeres donantes, y se supo que algunos miembros de su laboratorio también fueron donantes. Hwang ofreció su renuncia, aunque no reconoció su responsabilidad en los procedimientos de adquisición de óvulos. Muchos comentaristas concordaron en que podía haber diferencias en el tratamiento del tema de la coerción en Asia, pero aún así, el ocultamiento de la verdad sobre la donación llevó a que se pusiera en tela de juicio la ética del trabajo de Hwang, más aún luego de que el Comité Nacional de Bioética de Corea del Sur encontrara que no todas las donantes recibieron información sobre los graves riesgos a los que se exponían, y que las 16 donantes requerían hospitalización por los efectos adversos tras el procedimiento. Estos cuestionamientos éticos motivaron nuevas y mayores indagaciones sobre el trabajo de Hwang.
      </p>
      <p>
        Es así que, en un sitio web dirigido a biólogos (Centro de Información de Investigaciones Biológicas, BRIC), se puso al descubierto la existencia de datos discordantes en los análisis del ADN en uno de los artículos de Hwang en Science. Por otro lado, se le acusó también de haber falsificado unas imágenes fotográficas publicadas en esa revista.
      </p>
      <img className={"right"} src={m2Img2} alt={m2Img2} />
      <p>
        Fue entonces que la UNS decidió conformar un comité para investigar esos alegatos de mala conducta científica, encontrando –en el breve lapso de cuatro semanas–, con respecto a la publicación del 2005, que (1) en lugar de las 11 líneas de células madre embrionarias, los datos disponibles correspondían sólo a dos; (2) que las otras líneas celulares fueron creadas por manipulación digital de las imágenes; y (3) que las dos líneas celulares válidas no fueron derivadas por transferencia de células somáticas, sino por fecundación de óvulos in vitro. En este caso, el comité estableció que todos los datos de la publicación fueron inventados, incluyendo los resultados de las pruebas de fingerprinting del ADN, las fotografías de teratoma, los cuerpos embrioides, y los isotipos de MHC-HLA y cariotipo.
      </p>
      <p>En relacion con el artículo del 2004, el comité envió 23 muestras a tres centros independientes. Los resultados idénticos permitieron concluir que los datos presentados en la publicación habían sido falsificados, incluyendo los análisis de fingerprinting de ADN y las fotografías de las células. Con los datos falseados se pretendía mostrar que las células madre correspondían al ADN del proveedor, cuando eso no era cierto.</p>
      <p>
        Respecto a la competencia del equipo del laboratorio de Hwang, se confirmó que poseían la técnica de creación de células embrionarias humanas clonadas, aunque los resultados reales eran menores a lo declarado por Hwang, pues la mayoría de los blastocistos se encontraban en mal estado. Se determinó entonces que carecía de sustento científico el anuncio sobre el exitoso nacimiento de linajes celulares a partir de células madre.
      </p>
      <p>
        Finalmente, se obtuvo información sobre los procesos de adquisición de óvulos, encontrándose que el número de óvulos usado era mucho mayor al que se podría establecer sobre la base de los datos publicados. El comité concluyó que la donación fue voluntaria, pero ocho miembros del laboratorio afirmaron que el mismo Hwang participó en el procedimiento, algo que él había negado.
      </p>
      <p>
        Tras la publicación del informe oficial del comité investigador de la UNS, Hwang se disculpó por el fiasco en una conferencia de prensa, pero negó el engaño, acusó a otros miembros de su equipo de haberle mentido ofreciéndole datos falsos, y habló de conspiración, sabotaje y de la posibilidad de robo de materiales del laboratorio. En el 2006, la fiscalía de su país lo acusó de fraude, malversación de fondos y violación de la bioética, condenándosele luego a dos años de cárcel suspendida.
      </p>
      <p>
        *Fuente: P. Kakuk (2009). Sci Eng Ethics 15:545-562. Con autorización de los editores.
      </p>
      <h2>
        Para reflexionar:
      </h2>
      <ul>
        <li>¿Por qué cree usted que ocurrió esto en el caso del Dr. Hwang?</li>
        <li>¿Qué pudo haberlo motivado a actuar de ese modo?</li>
        <li>¿Qué tipos de mala conducta científica reconoce en este caso?</li>
        <li>¿Qué principios de la integridad científica y qué prácticas de conducta responsable en investigación fueron vulnerados?</li>
        <li>¿Qué nivel de responsabilidad podemos reconocer en la Universidad, los coautores de los artículos, el equipo de trabajo, y las revistas que publicaron esos artículos?</li>
        <li>¿Cómo se podría prevenir que sucedan nuevos casos como este?</li>
        <li>Con respecto a la comunidad en general, ¿cuáles son las posibles consecuencias de este tipo de situaciones?</li>
      </ul>
    </>
  )
}

export const Contenido22 = () => {
  return (
    <>
      <h1>Objetivos</h1>
      <ul>
        <li>Reconocer qué es la MCC, haciendo referencia principalmente a situaciones como la fabricación de datos, la falsificación y el plagio.</li>
        <li>Reconocer las prácticas cuestionables en investigación.</li>
        <li>Presentar algunos casos históricos de MCC.</li>
        <li>Reconocer la frecuencia, los factores de riesgo y las formas de prevención de casos de MCC.</li>
        <li>Identificar las normas y políticas existentes sobre MCC.</li>
        <li>Mostrar cómo se deben manejar los casos de MCC.</li>
      </ul>
    </>
  )
}

export const Contenido23 = () => {
  return (
    <>
      <h1>Ver clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/psJiMQCJHTk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

export const Contenido24 = () => {

  const [cap24, setcap24] = useState(-1)


  return (
    <>
      <h1>Estudio Sobre Salud Sexual y Reproductiva*</h1>
      <img className={"left"} src={m2Img3} alt={m2Img3} />
      <p>Para la realización de un estudio sobre salud sexual y reproductiva se contrató y capacitó a un grupo de encuestadoras. La capacitación incluyó un curso de ética en investigación.</p>
      <p>El trabajo de campo consistía en la realización de encuestas domiciliarias a mujeres y sus parejas. Una semana después de haberse iniciado esta labor, la coordinadora del estudio realizó una supervisión, en la que halló varias irregularidades en la información recogida por una de las encuestadoras, incluyendo la recolección de datos incompletos con inconsistencias.</p>
      <p>Ante esto, la coordinadora informó al investigador principal. Se decidió conversar con la encuestadora, reforzar el entrenamiento y darle una nueva oportunidad para continuar colaborando con el estudio, pero con una supervisión más cercana.</p>
      <p>No obstante, durante un control de calidad de los datos, se encontró que la información recogida por esta encuestadora seguía presentando inconsistencias. Se decidió entonces revisitar a algunas de las mujeres entrevistadas por dicha encuestadora, y se supo entonces que no se había encuestado a sus parejas, sino que ellas mismas habían respondido a las preguntas destinadas a ellos; e incluso, algunas mujeres habían recibido materiales e incentivos (como hojas informativas y gorros) que se debía entregar a sus parejas, no a ellas.</p>
      <p>Además, la encuestadora había recabado información de mujeres que no cumplían con los criterios de elegibilidad, y había encuestado también a transeúntes, cuando todos los procedimientos debieron ser realizados en los domicilios de las personas. Por otro lado, varios datos reportados por la encuestadora no coincidían con la información obtenida durante las visitas de verificación, incluyendo datos básicos como las iniciales de las personas, sus fechas de nacimiento, entre otros.</p>
      <p>Por lo hallado en las indagaciones, se decidió separar del estudio a la encuestadora e informar sobre lo ocurrido a la institución responsable de la investigación.</p>
      <p>*Caso ficticio</p>
      <h2>¿Cómo calificaría usted el comportamiento de la encuestadora en este caso?</h2>
      <ol type={"A"}>

        <CaseOption>
          <b onClick={() => { cap24 === 1 ? setcap24(-1) : setcap24(1) }}>
            Mala conducta científica
          </b>
          <br />
          {
            cap24 === 1
              ? (
                <p>CORRECTO Estamos ante un caso de mala conducta científica, y específicamente de fabricación y falsificación de datos por parte de la encuestadora. A pesar de haber recibido entrenamiento en ética y en los procedimientos del estudio, ella actuó deliberada e intencionalmente al transgredir lo estipulado en el protocolo, y al consignar datos que no correspondían con la realidad.</p>
              )
              : ""
          }
        </CaseOption>


        <CaseOption>
          <b onClick={() => { cap24 === 2 ? setcap24(-1) : setcap24(2) }} >
            Prácticas cuestionables en investigación
          </b>
          <br />
          {
            cap24 === 2
              ? (
                <p>INCORRECTO Si bien las prácticas cuestionables en investigación incluyen la inexactitud en la información reportada, en este caso las inconsistencias e irregularidades en los datos no provienen solo de errores, sesgos u omisiones de la encuestadora, sino que implican actos deliberados e intencionales de fabricación y falsificación de datos, como se pudo comprobar en las visitas de verificación. Aquí, el carácter intencional y deliberado de las acciones de la encuestadora confieren a este caso la categoría de mala conducta científica.</p>
              )
              : ""
          }
        </CaseOption>


        <CaseOption>
          <b onClick={() => { cap24 === 3 ? setcap24(-1) : setcap24(3) }} >
            Ambos
          </b>
          <br />
          {
            cap24 === 3
              ? (
                <p>INCORRECTO Las prácticas cuestionables en investigación son aquellas que violan los valores tradicionales de la investigación y que pueden dañar sus procesos, abarcando aspectos como la inexactitud, la mala representación de las contribuciones científicas y el sesgo. No obstante, tales prácticas no necesariamente implican actos deliberados e intencionales de transgresión de las normas, lo cual sí se da en este caso, en que la encuestadora fabricó y falsificó datos, y no respetó el protocolo del estudio. Entonces, sus acciones constituyen mala conducta científica por su carácter intencional y deliberado.</p>
              )
              : ""
          }
        </CaseOption>

      </ol>
    </>
  )
}

export const Contenido30 = () => {
  return (
    <>
      <h1>Módulo 3: Plagio</h1>
      <p>El plagio, relativamente frecuente en el mundo académico, es la apropiación, presentación o utilización de material intelectual ajeno, sin el debido reconocimiento de su fuente original. Este módulo aborda las diversas formas en que se presenta el plagio, presenta ejemplos relevantes, revisa las maneras apropiadas de citar las fuentes, y presenta recomendaciones para detectar el plagio y para actuar en estos casos.</p>
      <img className={"big"} alt={modulo3} src={modulo3} />
    </>
  )
}

export const Contenido31 = () => {
  return (
    <>
      <h1>“Cortar y pegar” Contenidos tomados de Internet para la elaboración de trabajos universitarios*</h1>
      <img className={"left"} alt={m3Img1} src={m3Img1} />
      <p>En una Facultad de Medicina, el profesor responsable del curso de Salud Comunitaria le encargó a sus alumnos del primer año de estudios que realizaran trabajos monográficos individuales basados en los temas tratados en las clases.</p>
      <p>Una vez cumplido el plazo establecido, los estudiantes entregaron sus trabajos. Uno de los alumnos había preparado un ensayo sobre estrategias de prevención de infecciones de transmisión sexual. Al revisarlo, el profesor advirtió en el texto distintos estilos de redacción: mientras que la introducción estaba redactada en primera persona, en la sección siguiente se había adoptado un estilo impersonal de escritura. Esto le generó sospechas sobre la originalidad del trabajo.</p>
      <p>Para despejar sus dudas, el profesor decidió tomar al azar algunos segmentos del texto y los introdujo en un buscador de internet, encontrando que la mayor parte del trabajo provenía de dos páginas web, las cuales no aparecían citadas como fuente de la información.</p>
      <p>*Caso ficticio</p>
      <p>El profesor citó al alumno para conversar sobre su ensayo, y se sorprendió al notar que el joven no parecía estar consciente de que había procedido mal al haber tomado grandes segmentos de texto para simplemente “pegarlos” en su trabajo, obviando además citar a los autores y consignar las fuentes de la información. De hecho, el joven le contó al profesor que eso era lo que él y sus compañeros hacían habitualmente en el colegio durante la secundaria.</p>
      <p>El profesor le explicó entonces al alumno que debía leer, entender e interpretar la información antes de emplearla para sus trabajos, que tenía que entrecomillar los textos tomados de otras fuentes, y citar además a los autores y trabajos consultados. También le indicó que no era apropiado tomar secciones enteras o grandes cantidades de texto, y le dio algunos consejos sobre cómo resumir y parafrasear la información.</p>
      <p>Al comentar el caso con sus colegas de la Facultad, dos de ellos mencionaron que habían tenido que manejar situaciones similares en sus propios cursos. Juntos fueron a conversar sobre el tema con las autoridades de la Facultad, quienes decidieron convocar a los demás profesores para discutir en conjunto sobre las mejores maneras de promover una conducta responsable en las actividades académicas desde el Pregrado, y el establecimiento de políticas institucionales para manejar los casos de plagio y otras malas prácticas que pudieran presentarse a futuro.</p>
      <h2>Para reflexionar:</h2>
      <ul>
        <li>La manera en que el alumno elaboró su trabajo universitario, ¿constituye plagio, aún si él no era plenamente consciente de lo que estaba haciendo?</li>
        <li>Si una persona “corta y pega” contenidos de internet para elaborar un trabajo académico, ¿es suficiente citar las fuentes de la información?</li>
        <li>En relación con el plagio, ¿qué diferencias pueden haber entre “cortar y pegar” contenidos de internet, y tomarlos de fuentes impresas?</li>
        <li>¿Qué podrían hacer las autoridades de aquella Facultad para promover una conducta responsable en las actividades académicas, entre los alumnos de Pregrado?</li>
        <li>¿Qué políticas institucionales se podrían adoptar en esa Facultad para manejar y sancionar los casos de plagio?</li>
        <li>¿Se puede afirmar que la disponibilidad de acceso a internet contribuye a que se cometa plagio (haciéndolo más facil)? ¿O esto ocurre más por factores personales (por la voluntad de plagiar)?</li>
      </ul>
    </>
  )
}

export const Contenido32 = () => {
  return (
    <>
      <h1>Objetivos</h1>
      <ul>
        <li>
          Definir qué es el plagio.
        </li>
        <li>
          Identificar los tipos de plagio.
        </li>
        <li>
          Mostrar cuándo y de qué modo se debe citar y entrecomillar.
        </li>
        <li>
          Dar a conocer las maneras de detectar el plagio.
        </li>
        <li>
          Presentar algunas pautas de acción frente a casos de plagio.
        </li>
      </ul>
    </>
  )
}

export const Contenido33 = () => {
  return (
    <>
      <h1>Ver Clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ywuU8ZMvefI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

export const Contenido34 = () => {
  const [cap34, setcap34] = useState(-1);

  return (
    <>
      <h1>Apropiación de una propuesta de investigación sometida a un fondo concursable*</h1>
      <img className={"left"} src={m3Img2} alt={m3Img2} />
      <p>Una estudiante de un programa de Maestría en Salud Ambiental recibió el anuncio de una convocatoria para presentar proyectos de investigación relacionados con alternativas para reducir la contaminación ambiental. La convocatoria fue lanzada por una oficina de su Universidad, con fondos de una agencia de cooperación internacional.</p>
      <p>La estudiante se presentó a dicho concurso con una propuesta basada en su tesis de maestría, la cual planteaba evaluar un nuevo método para el manejo sostenible de residuos sólidos en zonas urbanas con baja cobertura de servicios sanitarios.</p>
      <p>Cuando se hicieron públicos los resultados del concurso, la estudiante vio que su proyecto no había sido seleccionado para recibir financiamiento. Decidió entonces revisarlo para introducir mejoras y poder presentarlo a una nueva convocatoria.</p>
      <p>*Caso ficticio</p>
      <h2>De Acuerdo a lo Aprendido en este módulo sobre plagio, ¿cuál de Las Siguientes Afirmaciones Representa Mejor Lo Que Vemos En Este Caso?</h2>
      <ol type={"A"}>
        <CaseOption>
          <b onClick={() => { cap34 === 1 ? setcap34(-1) : setcap34(1) }} >Estamos ante un caso claro de plagio de texto</b>
          <br />
          {
            cap34 === 1
              ? (
                <p>INCORRECTO Para calificar el caso como plagio de texto, tendríamos que saber si el profesor tomó literalmente el texto de la estudiante, o parte de él, para presentarlo como propio. Pero la información disponible no permite saber esto. Lo que se señala en el caso es que el proyecto del profesor era “similar” al de la estudiante, por lo menos en el concepto, y que él alegó haber introducido modificaciones. Se trata entonces de un caso de plagio de ideas, que se define como “la apropiación de ideas en forma completa o parcial, o con modificaciones superficiales o mínimas, utilizándolas sin dar los créditos a quienes las originaron.”
                </p>
              )
              : ""
          }
        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap34 === 2 ? setcap34(-1) : setcap34(2) }} >Hay factores atenuantes o mitigantes, por la colaboración del profesor ante las autoridades de la Universidad</b>
          <br />
          {
            cap34 === 2
              ? (
                <p>INCORRECTO Es cierto que el profesor colaboró con las autoridades de la Universidad que se proponían esclarecer el caso, y que esta colaboración podría ser un factor atenuante o mitigante al momento de evaluar la gravedad de su falta. Sin embargo, debemos considerar también que, si el proyecto que él presentó luego como suyo estuvo basado en una propuesta original sometida a un concurso en el cuál él era miembro del Jurado Evaluador, eso constituiría un factor agravante, pues obtuvo una idea de una manera inapropiada. Tomando esto en cuenta, el caso no se define mejor por la presencia de aquel factor mitigante, sino por el hecho central del plagio de ideas, es decir, “la apropiación de ideas en forma completa o parcial, o con modificaciones superficiales o mínimas, utilizándolas sin dar los créditos a quienes las originaron.”
                </p>
              )
              : ""
          }

        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap34 === 3 ? setcap34(-1) : setcap34(3) }} >Se trata de un caso de plagio de ideas</b>
          <br />
          {
            cap34 === 3
              ? (
                <p>
                  CORRECTO Es un caso de plagio de ideas porque, aún si el profesor introdujo modificaciones en ciertos aspectos del proyecto, persiste el hecho de que el concepto y el diseño original hayan sido elaborados por la estudiante, quien no fue reconocida como autora de la idea. Según la definición presentada en el módulo, este tipo de plagio involucra “la apropiación de ideas en forma completa o parcial, o con modificaciones superficiales o mínimas, utilizándolas sin dar los créditos a quienes las originaron.” Además, también es cuestionable la manera en que el profesor obtuvo la idea del proyecto, ya que la tomó de una propuesta que se encontraba en evaluación para un fondo concursable, siendo él uno de los miembros del Jurado Evaluador. Esto constituye un factor agravante, incluso si el profesor decidió cooperar con las autoridades que evalúan el caso.
                </p>
              )
              : ""
          }
        </CaseOption>
      </ol>
    </>
  )
}

export const Contenido40 = () => {
  return (
    <>
      <h1>Módulo 4: Autoría Responsable</h1>
      <p>Con la evolución de la actividad científica, que involucra cada vez más el trabajo colaborativo, se agudiza la ya existente dificultad para la atribución de créditos y responsabilidades al momento de reportar los hallazgos de la investigación y se complejiza aun mas este proceso. Al respecto, este módulo contiene definiciones, casos y normas que ayudarán a determinar cuándo y bajo qué criterios las contribuciones al trabajo científico implican el reconocimiento de autorías.</p>
      <img className={"big"} src={modulo4} alt={modulo4} />
    </>
  )
}

export const Contenido41 = () => {
  return (
    <>
      <h1>Autoría en la Publicación de Trabajos Universitarios*</h1>
      <img className={"left"} src={m4Img1} alt={m4Img1} />

      <p>Con la evolución de la actividad científica, que involucra cada vez más el trabajo colaborativo, se agudiza la ya existente dificultad para la atribución de créditos y responsabilidades al momento de reportar los hallazgos de la investigación y se complejiza aun mas este proceso. Al respecto, este módulo contiene definiciones, casos y normas que ayudarán a determinar cuándo y bajo qué criterios las contribuciones al trabajo científico implican el reconocimiento de autorías.</p>
      <p>
        Al finalizar el curso, ella se dirigió a los integrantes de los grupos “A” y “B”, que habían entregado los informes de mejor calidad, alentándolos a que los publicaran en alguna revista científica local. Ambos grupos acogieron la recomendación.
      </p>
      <p>
        Luego, los miembros del grupo “A” decidieron poner a discusión quién debería aparecer como primer autor del trabajo. Como no llegaban a un acuerdo, un estudiante sugirió lanzar una moneda al aire para establecer al azar el orden de prioridad de los nombres. Mientras hacían esto, otro miembro del grupo propuso dividir los resultados en tres partes y preparar tres manuscritos; según él, así todos podrían tener más publicaciones y cada uno sería el autor principal de un artículo. Los demás acogieron la propuesta.
      </p>
      <p>
        Por otro lado, los miembros del grupo “B” sí habían alcanzado un acuerdo sobre el orden de aparición de los autores. Pero además, uno de ellos sugirió ir a conversar con sus compañeros del otro grupo llevándoles una propuesta: si, en sus manuscritos, los alumnos del grupo “A” agregaban a los del “B” como co-autores, éstos harían lo mismo con los del “A” en su propio artículo. De este modo –decía él-, todos se beneficiarían aumentando la cantidad de publicaciones de cada uno.
      </p>
      <p>
        En efecto, los jóvenes del grupo “B” se reunieron con sus pares del otro grupo. Si bien dos alumnas manifestaron sus dudas sobre si estaba bien o mal hacer lo que se había planteado, al final los demás las convencieron con el argumento de que todos habían llevado “el mismo curso”.
      </p>
      <p>
        En la misma reunión se discutió también sobre las revistas a las que enviarían los trabajos, y las posibilidades de que efectivamente sean publicados. Al respecto, un alumno sostuvo que era más probable la aceptación de la publicación si entre los nombres de los autores aparecía el de un investigador prestigioso y respetado en la comunidad científica. La mayoría estuvo de acuerdo con él, y le encargaron que llevara la propuesta a un profesor con el que tuvieron un curso el año anterior, un ex decano de la Facultad a quien la Universidad había premiado recientemente en reconocimiento por su su trayectoria académica.
      </p>
      <p>
        En efecto, el alumno contactó personalmente al mencionado profesor, le dio los detalles de todo lo que planeaban hacer, y le comunicó la propuesta de incluirlo como co-autor de los manuscritos. El profesor, por su parte, le pidió al joven que reuniera a sus compañeros para darles una respuesta y conversar con ellos sobre el tema.
      </p>
      <p>
        Una vez reunido con los integrantes de los dos grupos, el profesor les dijo que no podía acceder al pedido, y les explicó el porqué de su decisión analizando junto a ellos, punto por punto, los problemas que encontraba en las propuestas y decisiones surgidas entre ellos con respecto a la autoría y la publicación de sus trabajos.
      </p>
      <h2>
        Para reflexionar:
      </h2>
      <ul>
        <li>¿Por qué el profesor no habría aceptado ser incluido entre los autores de los trabajos de los estudiantes?</li>
        <li>¿Cuál sería la manera más apropiada de determinar el orden de aparición de los nombres de los autores en un manuscrito?</li>
        <li>¿Qué opina del procedimiento de lanzar una moneda al aire para establecer al azar el orden de prioridad de los nombres de los autores?</li>
        <li>Si los alumnos de los dos grupos llevaron juntos el mismo curso, ¿se justifica que los miembros del grupo “A” figuren como autores del trabajo del grupo “B” y viceversa?</li>
        <li>
          Más allá de la forma de atribuir autorías, ¿qué otros problemas relacionados con la publicación de trabajos científicos puede identificar en este caso?
        </li>
        <li>
          ¿Qué podría hacer la Universidad para evitar problemas relacionados con la atribución de autorías en los trabajos científicos?
        </li>
      </ul>
    </>
  )
}

export const Contenido42 = () => {
  return (
    <>
      <h1>Objetivos</h1>
      <ul>
        <li>Ofrecer pautas para asignar apropiadamente las autorías en las publicaciones científicas.</li>
        <li>Aprender cómo identificar y evitar los problemas más frecuentes relacionados con las autorías.</li>
        <li>Conocer algunas experiencias y problemas documentados relacionados con las autorías en las actividades de investigación.</li>
      </ul>
    </>
  )
}

export const Contenido43 = () => {
  return (
    <>
      <h1>Ver Clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/-Ip2CENmrF8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

export const Contenido44 = () => {
  const [cap44, setcap44] = useState(-1)
  return (
    <>
      <h1>Discrepancias por el Reconocimiento de Autorías en un Trabajo Publicado*</h1>
      <img className={"left"} src={m4Img2} alt={m4Img2} />
      <p>En un centro de investigación especializado en enfermedades infecciosas, se encargó a dos especialistas la elaboración de un proyecto para investigar los factores sociales que podían estar contribuyendo a la alta incidencia de casos de tuberculosis en algunas zonas urbano-marginales de una ciudad ubicada en una provincia peruana.</p>
      <p>El responsable del estudio era un médico epidemiólogo con amplia experiencia en el tema, quien trabajó con un sociólogo en el diseño de la investigación. Luego de revisar la literatura científica disponible, decidieron realizar un estudio basado en encuestas domiciliarias en los barrios donde se había presentado el mayor número de nuevos casos de tuberculosis durante el último año, para lo cual elaboraron un breve cuestionario estructurado que debía ser aplicado en 160 hogares seleccionados aleatoriamente en tres barrios de aquella ciudad.</p>
      <p>
        Como tenían planeado publicar los resultados del estudio en una revista médica, el médico y el sociólogo acordaron que este último redactaría el manuscrito y sería el primer autor, mientras que el médico ocuparía la última ubicación como autor senior, independientemente de si integraban o no a más colaboradores al estudio.
      </p>
      <p>
        Llegado el momento de recolectar los datos, los investigadores solicitaron a la institución que contrate a una persona para aplicar el cuestionario en los lugares previamente seleccionados. Se contrató entonces a una trabajadora social, quien visitó los domicilios de acuerdo a una lista de direcciones, completó el trabajo de campo en dos meses, y luego fue contratada nuevamente para digitar la información en una base de datos (diseñada por el sociólogo), labor que realizó durante dos semanas adicionales.
      </p>
      <p>
        Para el análisis de la información, los investigadores convocaron a un especialista en Estadística, miembro de otra institución, quien revisó el protocolo del estudio y el plan de análisis, y recomendó algunos cambios y la inclusión de nuevos procedimientos para mejorar el análisis de los datos. Las sugerencias fueron aceptadas. El Estadístico procedió entonces a realizar el análisis, y entregó los resultados en el plazo de una semana.
      </p>
      <p>
        Durante el proceso de redacción del manuscrito, el sociólogo le propuso al médico incluir al Estadístico como segundo autor del trabajo en reconocimiento de su contribución en el análisis. Los tres estuvieron de acuerdo y, llegado el momento, tuvieron ocasión de revisar y aprobar la última versión del manuscrito, antes de someterlo a una revista local para su evaluación y publicación.
      </p>
      <p>
        Luego de unos meses, el trabajo apareció publicado en aquella revista. Cuando se divulgó al interior de la institución, la trabajadora social, que continuaba trabajando allí para otros proyectos, manifestó su disconformidad por no haber sido incluida entre los autores. Si bien su nombre aparecía mencionado en la sección de “agradecimientos” del artículo, ella consideraba que su aporte ameritaba el reconocimiento de una autoría, dado el trabajo y el tiempo que había dedicado al proyecto, y al hecho de haber recolectado ella todos los datos del estudio. Y la situación le parecía aún más “injusta” debido a que el analista sí había sido incluido entre los autores, cuando él había participado en el proyecto por un tiempo mucho más corto.
      </p>
      <p>
        *Caso ficticio
      </p>
      <h2>De Acuerdo a lo Aprendido en este Módulo sobre Autoría Responsable, ¿Cuál de las siguientes afirmaciones representa mejor lo que vemos en este caso?</h2>
      <ol type={"A"}>
        <CaseOption>
          <b onClick={() => { cap44 === 1 ? setcap44(-1) : setcap44(1) }}>
            La trabajadora social debió ser considerada entre los autores del manuscrito por su aporte en el trabajo de campo y la administración de datos.
          </b>
          <br />
          {
            cap44 === 1
              ? (
                <p>
                  INCORRECTO Si bien es cierto que la trabajadora social contribuyó al proyecto realizando la recolección y digitación de datos durante dos meses y medio, estas tareas no implicaron un aporte intelectual sustancial. Recordemos que se le encargó aplicar un cuestionario estructurado (con preguntas preestablecidas) en lugares previamente seleccionados, e ingresar luego la información a una base de datos diseñada por el sociólogo. Es decir, realizó una serie de tareas operativas estandarizadas y repetitivas. Por otro lado, tampoco participó en la elaboración, revisión o aprobación del manuscrito. Como vemos, no reunía los tres requisitos señalados en el módulo como necesarios para considerar una autoría: (1) haber realizado una contribución intelectual sustancial al estudio; (2) haber aportado en la concepción o el diseño del estudio, en la recolección, análisis o interpretación de los datos, o en el desarrollo de la publicación, preparando el manuscrito y/o revisándolo críticamente; y (3) haber dado su aprobación a la versión final del manuscrito.
                </p>
              )
              : ""
          }

        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap44 === 2 ? setcap44(-1) : setcap44(2) }}>
            La trabajadora social no reunía los requisitos para ser incluida entre los autores.
          </b>
          <br />
          {
            cap44 === 2
              ? (
                <p>
                  CORRECTO Para que una persona sea considerada entre los autores de un trabajo, debe reunir necesariamente tres requisitos: (1) haber realizado una contribución intelectual sustancial al estudio; (2) haber aportado en la concepción o el diseño del estudio, en la recolección, análisis o interpretación de los datos, o en el desarrollo de la publicación, preparando el manuscrito y/o revisándolo críticamente; y (3) haber dado su aprobación a la versión final del manuscrito. En el caso presentado, la trabajadora social participó en la recolección y el manejo de datos, pero su aporte no constituyó una contribución intelectual (ya que realizó tareas estandarizadas y repetitivas), ni intervino en la preparación, revisión o aprobación del manuscrito. Por lo tanto, no reunía los requisitos para ser incluida entre los autores.
                </p>
              )
              : ""
          }
        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap44 === 3 ? setcap44(-1) : setcap44(3) }}>
            La contribución de la trabajadora social fue mayor a la del analista.
          </b>
          <br />
          {
            cap44 == 3
              ? (
                <p>
                  INCORRECTO Las contribuciones de la trabajadora social y las del analista fueron de dos tipos diferentes. Si bien ella colaboró durante un tiempo mayor (dos meses y medio) en una labor que implicó una mayor carga de trabajo, esto no representó una contribución intelectual, pues realizó tareas operativas estandarizadas y repetitivas. El aporte del analista, en cambio, sí fue de carácter intelectual, ya que propuso cambios en el tratamiento de los datos y nuevos procedimientos de análisis; y además, revisó y aprobó la versión final del manuscrito. En ese sentido, en relación con la autoría y la publicación, no cabe comparar las contribuciones de ambos en términos de “mayor” o “menor”, siendo que se trata de diferentes tipos de aporte: operativo en un caso, e intelectual en el otro.
                </p>
              )
              : ""
          }

        </CaseOption>
      </ol>
    </>
  )
}

export const Contenido50 = () => {
  return (
    <>
      <h1>Módulo 5: Publicación Responsable</h1>
      <p>El avance científico depende, en gran medida, de la divulgación de los hallazgos de las investigaciones, lo cual implica la observación de determinados estándares profesionales y criterios éticos. No obstante, ya sea por la presión para publicar o por otros motivos, suelen darse casos de publicaciones redundantes, duplicadas, “infladas” o fragmentadas. Este módulo aborda éstos y otros asuntos relacionados con la responsabilidad e integridad de los investigadores al momento de dar a conocer los resultados de su trabajo.</p>
      <img className={"big"} src={modulo5} alt={modulo5} />
    </>
  )
}

export const Contenido51 = () => {
  return (
    <>
      <h1>Estudio Publicado en más de una Revista*</h1>
      <img className={"left"} src={m5Img1} alt={m5Img1} />
      <p>
        Dos jóvenes obstetrices, estudiantes de un programa de Maestría en Salud Pública, trabajaban en el servicio de Planificación Familiar de un hospital público. Ambas habían decidido realizar juntas un trabajo de investigación sobre las preferencias de métodos anticonceptivos entre las mujeres usuarias del servicio. Para esto, convocaron a dos colegas que laboraban en el mismo hospital, quienes las apoyaron en la aplicación de una encuesta a 230 mujeres durante seis meses.
      </p>
      <p>
        Un profesor de la Maestría guió a las estudiantes en el análisis de los datos y la redacción de un manuscrito, que finalmente decidieron publicar en la revista médica de su Universidad, incluyendo como coautoras a sus colegas del hospital, en reconocimiento por sus aportes durante la etapa de análisis de los resultados, y al profesor en la última ubicación, en calidad de autor senior.
      </p>
      <p>
        Poco después de someter el trabajo a la revista, una de las autoras sugirió a sus colegas enviarlo también a una revista de alcance nacional, con el propósito de darle “mayor difusión”. Las demás aceptaron la sugerencia y le encargaron el envío del mismo manuscrito a esa otra revista, permitiéndole aparecer en la posición de primera autora (pues en el trabajo inicial era la segunda).
      </p>
      <p>
        Algunos meses después, el trabajo apareció publicado en las dos revistas. El profesor no sabía que el manuscrito había sido sometido a una nueva revista, pero tampoco hizo mayor comentario cuando lo supo.
      </p>
      <p>
        Al año siguiente, una de las colaboradoras del artículo, quien aparecía como tercera autora, planteó a sus colegas la idea de traducirlo al inglés para enviarlo a una revista estadounidense. Todas estuvieron de acuerdo, y solicitaron la ayuda de un estudiante norteamericano de Medicina que se encontraba en el Perú para realizar la traducción, ofreciéndole incluirlo entre los autores. El trabajo fue aceptado y publicado en aquella revista internacional.
      </p>
      <p>
        Poco después, la colaboradora que figuraba en el cuarto lugar como coautora del estudio publicado inicialmente se inscribió en el programa de Maestría que habían llevado sus colegas. Como parte de su proyecto de tesis, decidió aplicar el mismo cuestionario empleado en aquel estudio a más mujeres usuarias del servicio de Planificación Familiar en el que se había trabajado previamente. Luego, la obstetriz pidió a sus antiguas colaboradoras que le faciliten la base de datos original del estudio que hicieron juntas, para agregar los datos obtenidos de 50 nuevos casos de mujeres, apelando a la amistad y comprometiéndose a incluirlas como coautoras en un nuevo paper que planeaba publicar después de presentar su tesis. En efecto, sus colegas le enviaron la base de datos. Ella incorporó la nueva información y realizó un análisis basado en 280 casos.
      </p>
      <p>
        Eventualmente, el profesor que aparecía como autor senior en los artículos previos supo del trabajo de la obstetriz, y le manifestó que no le parecía conveniente publicar nuevamente el trabajo. No obstante, ella consideró que se trataba de una versión “actualizada”, y decidió someterlo de todas formas a otra revista distinta de las anteriores, pero con una variación en el título, e incluyendo a sus antiguas colegas como coautoras.
      </p>
      <p>
        Este trabajo también fue publicado en una revista médica. Sin embargo, pocos meses después, el editor principal tomó conocimiento del artículo que las obstetrices habían publicado en la revista estadounidense, notando en él claras similitudes con el que acababa de publicar su propia revista. El editor buscó entonces los apellidos de las autoras en las principales bases de datos de literatura científica nacional e internacional, encontrando así que tenían un mismo artículo publicado tres veces en distintas revistas, y que el cuarto artículo era también muy similar, salvo por los nuevos casos agregados, que no aportaban una novedad sustancial respecto a los anteriores. Frente a esta situación, decidió publicar una retractación del artículo en el próximo número de su revista, escribir a la autora principal comunicándole las razones de esa decisión, e informar sobre el hecho a los editores de las otras tres revistas involucradas.
      </p>
      <p>
        La inclusión de nuevos datos no significó una variación sustancial respecto a los resultados obtenidos anteriormente. De hecho, luego de terminar su tesis, la obstetriz se limitó a “actualizar” los datos presentados en el primer trabajo publicado, que empleó como una “plantilla” para preparar un “nuevo” manuscrito, en el que ella figuraba ahora como primera autora.
      </p>
      <p>
        Eventualmente, el profesor que aparecía como autor senior en los artículos previos supo del trabajo de la obstetriz, y le manifestó que no le parecía conveniente publicar nuevamente el trabajo. No obstante, ella consideró que se trataba de una versión “actualizada”, y decidió someterlo de todas formas a otra revista distinta de las anteriores, pero con una variación en el título, e incluyendo a sus antiguas colegas como coautoras.
      </p>
      <p>
        Este trabajo también fue publicado en una revista médica. Sin embargo, pocos meses después, el editor principal tomó conocimiento del artículo que las obstetrices habían publicado en la revista estadounidense, notando en él claras similitudes con el que acababa de publicar su propia revista. El editor buscó entonces los apellidos de las autoras en las principales bases de datos de literatura científica nacional e internacional, encontrando así que tenían un mismo artículo publicado tres veces en distintas revistas, y que el cuarto artículo era también muy similar, salvo por los nuevos casos agregados, que no aportaban una novedad sustancial respecto a los anteriores. Frente a esta situación, decidió publicar una retractación del artículo en el próximo número de su revista, escribir a la autora principal comunicándole las razones de esa decisión, e informar sobre el hecho a los editores de las otras tres revistas involucradas.
      </p>
      <h2>Para reflexionar:</h2>
      <ul>
        <li>¿Qué problemas relacionados con la autoría y la publicación puede identificar en este caso?</li>
        <li>¿Qué motivaciones tuvieron o pudieron tener las autoras para publicar el trabajo más de una vez?</li>
        <li>¿Es válido traducir un artículo para publicarlo en una revista extranjera?</li>
        <li>¿Se justifica generar un nuevo artículo a partir de la incorporación de nuevos datos?</li>
        <li>¿Qué podemos decir de la manera en que las obstetrices atribuyeron y establecieron el orden de las autorías?</li>
        <li>¿Cómo evalúa la participación del profesor? ¿Actuó correctamente?</li>
        <li>¿Qué opina de las decisiones tomadas por el editor de la revista?</li>
        <li>Las revistas y las instituciones de formación que usted conoce, ¿tienen regulaciones o políticas para manejar situaciones como las presentadas en este caso? ¿Qué políticas o regulaciones conoce al respecto?</li>
        <li>¿Qué consecuencias puede acarrear la práctica de publicar varias veces el mismo trabajo?</li>
      </ul>
    </>
  )
}

export const Contenido52 = () => {
  return (
    <>
      <h1> Objetivos </h1>
      <ul>
        <li>Reconocer algunas prácticas comunes e inapropiadas en la publicación científica, especialmente las publicaciones redundantes: duplicadas, infladas y fragmentadas (estas últimas llamadas también publicaciones “salami”, por la idea que transmite este término, de un elemento cortado en varios pedazos).</li>
        <li>Reconocer algunas prácticas comunes e inapropiadas en la publicación científica, especialmente las publicaciones redundantes: duplicadas, infladas y fragmentadas (estas últimas llamadas también publicaciones “salami”, por la idea que transmite este término, de un elemento cortado en varios pedazos).</li>
      </ul>
    </>
  )
}

export const Contenido53 = () => {
  return (
    <>
      <h1>Ver Clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8CvKi-dhBB0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

export const Contenido54 = () => {
  const [cap54, setcap54] = useState(-1)
  return (
    <>
      <h1>Publicación de un Artículo Aparecido Previamente en otro Idioma*</h1>
      <img className={"left"} src={m5Img2} alt={m5Img2} />
      <p>Un grupo de especialistas en sexualidad desarrolló en el Perú una nueva herramienta web para capacitar a distancia a promotores de salud y a docentes de educación básica en el manejo de contenidos sobre educación sexual integral. La evaluación del sistema arrojó que éste había contribuido a mejorar notablemente los conocimientos sobre el tema entre los usuarios, y que además servía para ampliar la cobertura y reducir los costos de la capacitación.</p>
      <p>Los responsables del trabajo elaboraron un manuscrito con la descripción y los resultados de la evaluación del sistema, para publicarlo en inglés en una revista norteamericana especializada en educación en salud. Algunos meses después, el manuscrito fue aceptado y publicado en la revista.</p>
      <p>El artículo llegó a las manos de un funcionario de un agencia sanitaria colombiana dedicada a la educación en salud, quien consideró que la estrategia podía ser replicada en su país y que el trabajo merecía mayor difusión entre un público hispanohablante. El funcionario escribió entonces al autor principal del artículo y le propuso traducirlo al español para publicarlo en una revista colombiana de acceso abierto vía internet. El autor accedió a la propuesta, y dejo en manos del funcionario la traducción y la tarea de enviar el trabajo a esta nueva revista.</p>
      <p>Un mes después, la traducción estaba lista y el funcionario entregó el artículo al editor de la revista colombiana, señalando que se trataba de un trabajo aparecido previamente en inglés, y que se contaba ya con la autorización para publicarlo en español. La revista contemplaba en sus normas la publicación de traducciones, y el editor aceptó el artículo, que apareció tres meses después en el siguiente número de la revista, consignando la fuente original de la información.</p>
      <p>*Caso ficticio</p>
      <h2>De acuerdo a lo aprendido en este módulo sobre publicación responsable, ¿Cuál de las siguientes afirmaciones representa mejor lo que vemos en este caso?</h2>
      <ol type={"A"}>
        <CaseOption>
          <b onClick={() => { cap54 === 1 ? setcap54(-1) : setcap54(1) }}>El funcionario y el editor de la revista colombiana contaban con la autorización para publicar la traducción del artículo.</b>
          {
            cap54 === 1
              ? (
                <p>INCORRECTO Si bien es cierto que el autor principal aceptó que se tradujera el artículo para enviarlo a la revista colombiana, y que la publicación duplicada se justifica cuando hay un público distinto (hispanohablante en este caso), era necesario obtener la autorización expresa de la revista norteamericana para esta nueva publicación, ya que cuando los autores envían sus manuscritos a las revistas científicas, les ceden a éstas los derechos sobre esos trabajos, derechos que las revistas legalmente retienen en condición de exclusividad. Es muy posible que el funcionario y el editor de la revista colombiana hayan actuado de buena fe, y que el funcionario haya asumido que bastaba con la autorización del autor, pero la autorización debió ser solicitada a la revista norteamericana. Estamos entonces ante un caso de publicación duplicada no autorizada.</p>
              )
              : ""
          }
        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap54 === 2 ? setcap54(-1) : setcap54(2) }}>Se procedió correctamente al publicar la traducción consignando la fuente del artículo original en inglés.
          </b>
          <br />
          {
            cap54 === 2
              ? (
                <p>
                  INCORRECTO La revista colombiana procedió correctamente al consignar la fuente original del artículo traducido. Sin embargo, no bastaba con eso. Era necesario obtener la autorización expresa de la revista norteamericana para esta nueva publicación, ya que cuando los autores envían sus manuscritos a las revistas científicas, les ceden a éstas los derechos sobre esos trabajos, derechos que las revistas retienen legalmente en condición de exclusividad. Se trata entonces de un caso de publicación duplicada no autorizada, pues aún cuando el autor principal había aceptado que se publique la traducción del artículo, no se contaba con la autorización del titular de los derechos –la revista norteamericana. No obstante, el funcionario y el editor de la revista colombiana parecen haber actuado de buena fe, y es posible que el funcionario haya asumido que la aceptación del autor constituía una autorización para re-editar el artículo.
                </p>
              )
              : ""
          }
        </CaseOption>
        <CaseOption>
          <b onClick={() => { cap54 === 3 ? setcap54(-1) : setcap54(3) }}>
            Se trata de un caso de publicación duplicada no autorizada.
          </b>
          <br />
          {
            cap54 === 3
              ? (
                <p>
                  CORRECTO Cuando los autores envían sus manuscritos a las revistas científicas, les ceden a éstas los derechos sobre esos trabajos, derechos que las revistas legalmente retienen en condición de exclusividad. Entonces, no bastaba con la aceptación del autor principal para publicar la traducción del artículo, ni tampoco era suficiente consignar la fuente original en la nueva publicación. Aún cuando el funcionario y el editor pudieron haber actuado de buena fe, e incluso habiéndose procedido correctamente al consignar la fuente original en la nueva publicación, en este caso era necesario obtener una autorización expresa otorgada por la revista norteamericana.
                </p>
              )
              : ""
          }
        </CaseOption>
      </ol>
    </>
  )
}

export const Contenido60 = () => {
  return (
    <>
      <h1>Módulo 6: Conflictos De Interés</h1>
      <p>¿Que ocurre cuando los compromisos, motivaciones o intereses particulares de un investigador llegan a afectar o tienen potencial de influir en sus juicios, procedimientos o informes de investigación? Este módulo apunta a responder esta pregunta definiendo qué se entiende por conflicto de interés en el ámbito científico, identificando sus diversos tipos y revisando las normas existentes al respecto.</p>
      <img className={"big"} src={modulo6} alt={modulo6} />
    </>
  )
}

export const Contenido61 = () => {
  return (<>
    <h1>Estudio de Evaluación del Impacto Social y Ambiental de una Obra de Infraestructura*</h1>
    <img className={"left"} src={m6Img1} alt={m6Img1} />
    <p>Un gobierno regional del Perú otorgó a una compañía europea un contrato para construir una nueva carretera de 200 kilómetros que debía conectar a una ciudad de la Sierra con otra ubicada en la Amazonía. Para esto, los ingenieros de la empresa habían elaborado una propuesta técnica con mapas que incluían el trazo de la futura vía.</p>
    <p>Las regulaciones nacionales exigían a la compañía la realización de un estudio de evaluación del posible impacto social y ambiental de la obra, que según el proyecto atravesaba algunas áreas forestales y agrícolas. El estudio debía ser aprobado por una agencia estatal antes del inicio de las operaciones.</p>
    <p>Para la ejecución del estudio, los ejecutivos de la empresa decidieron contratar los servicios de un Instituto de Investigación adscrito a una Universidad local. Los directivos del Instituto aceptaron el encargo, negociaron el costo del estudio, establecieron un plazo de cuatro meses para la entrega de un informe preliminar, con un mes adicional para la entrega de un informe final, y conformaron un equipo multidisciplinario compuesto por profesionales de Ingeniería Ambiental, Geografía, Biología y Ciencias Sociales.</p>
    <p>El equipo trabajó durante tres meses en la zona, realizando encuestas y consultas comunitarias, mediciones geográficas y análisis ambientales. Por un lado, los especialistas en Ciencias Sociales encontraron que, en general, las poblaciones de las áreas ubicadas a lo largo de la vía proyectada aceptaban la construcción de la carretera, salvo por algunos agricultores que condicionaban su aceptación a la perspectiva de ser adecuadamente compensados por la afectación de sus tierras de cultivo. Pero por otro lado, un Ingeniero Ambiental advirtió que el trazo de la carretera atravesaba 35 kilómetros de una zona que se encontraba en proceso de ser declarada “Área Natural Protegida”, para lo cual había un expediente en curso ante las autoridades ambientales. Luego, los biólogos constataron que dicha zona tenía presencia de algunas especies silvestres que habían sido declaradas en grave peligro de extinción.</p>
    <p>Los especialistas consignaron esta información en el informe preliminar enviado a la compañía, anexando una estimación de las dimensiones y el valor las áreas de cultivo que podrían verse afectadas, una lista de las especies silvestres amenazadas, y un reporte sobre el estado del tramite en curso para declarar dicha zona “Área Natural Protegida”. En sus recomendaciones, el mismo informe sugería replantear el trazo proyectado para la carretera, para evitar que ésta pase por la zona ambientalmente sensible. Al respecto, se anexaba también una propuesta del geógrafo del equipo con dos alternativas de posibles nuevas rutas.</p>
    <p>Luego de leer el informe, los ejecutivos de la empresa consideraron que un replanteamiento del trazo implicaría una elevación significativa del costo estipulado en el contrato con el gobierno regional, lo cual reduciría sus márgenes de beneficio. Además, el asesor legal de la compañía recomendó continuar con el proyecto inicial, alegando que la zona ambientalmente sensible no contaba con protección estatal cuando obtuvieron el contrato, y que la autoridad ambiental tardaría en declarar la protección sobre esa zona, ya que ese trámite se había iniciado muy recientemente.</p>
    <p>La compañía envió una comunicación escrita al Instituto universitario, pidiendo que se realicen algunas modificaciones al informe, antes de la entrega de la versión final que se debía remitir a las autoridades estatales. Específicamente, solicitaba retirar varios párrafos que hacían referencia a las especies animales amenazadas y al expediente en curso para declarar la protección de la zona forestal aludida. En la misma comunicación la compañía dejaba entrever que pedía tales cambios en virtud de ser ésta la que pagaba el estudio</p>
    <h2>Para reflexionar:</h2>
    <ul>
      <li>¿Cómo deberían actuar los responsables del estudio en este caso?</li>
      <li>Si la empresa pagaba el estudio, ¿podía solicitar que se modifique el informe final?</li>
      <li>¿Qué intereses entraron en juego, considerando a todas las personas e instituciones mencionadas en este caso?</li>
      <li>¿Qué aspectos legales habría que considerar en este tipo de situaciones?</li>
      <li>¿Los responsables del estudio podrían negarse a acceder al pedido de la empresa?</li>
      <li>¿Sabe si las instituciones de investigación que usted conoce tienen normas o reglamentos que contemplen este tipo de situaciones?</li>
    </ul>
  </>
  )
}

export const Contenido62 = () => {
  return (
    <>
      <h1>Objetivos</h1>
      <ul>
        <li>Definir qué es conflicto de interés.</li>
        <li>Reconocer los diferentes tipos de conflictos de interés.</li>
        <li>Identificar algunas normas existentes sobre conflicto de interés.</li>
        <li>Presentar las formas de manejar los conflictos de interés en el campo de la investigación.</li>
      </ul>
    </>
  )
}

export const Contenido63 = () => {
  return (
    <>
      <h1>Ver clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/VaSQYTS-e3I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

export const Contenido64 = () => {
  return (
    <>
      <h1>Evaluación de Propuestas en un Concurso por Fondos de Investigación*</h1>
      <img className={"left"} src={m6Img2} alt={m6Img2} />
      <p>Un profesor de Biología conducía un proyecto de investigación sobre la reproducción de diversas especies marinas en una región costera del sur del Perú. Para esto había convocado la participación de tres jóvenes que llevaban uno de los cursos que él dictaba en la Universidad sede del proyecto.</p>
      <p>Con el apoyo de los estudiantes, el profesor logró conformar una extensa base de datos sobre varias especies acerca de las cuales aún había poca información en la literatura científica. Es así que, luego de escribir un paper reportando los resultados de un primer análisis, en coautoría con los jóvenes, los alentó a realizar ellos mismos sus propios análisis sobre variedades específicas de la fauna marina comprendida en el trabajo, ya que él tenía otras obligaciones que le restaban tiempo para trabajar más a fondo con la base de datos. </p>
      <p>Al año siguiente, el profesor fue convocado por su Universidad para formar parte del Jurado Evaluador en un concurso para el otorgamiento de fondos destinados a nuevas investigaciones en Biología Marina. Cuando revisaba las propuestas anónimas enviadas al concurso, se interesó especialmente por una que planteaba desarrollar un nuevo método para calcular los volúmenes aproximados de los cardúmenes de peces de determinada especie, en función de las estaciones climáticas. Al revisar más detenidamente la propuesta, encontró que los análisis programados parecían estar basados en la información que él mismo había recopilado junto a sus estudiantes, por lo que intuyó que muy probablemente esa propuesta había sido elaborada por uno de ellos.</p>
      <p>Antes de entregar su evaluación al Presidente del Jurado, decidió llamar a sus ex-estudiantes para averiguar si alguno había enviado ese proyecto al concurso, confirmado así que, efectivamente, uno de ellos era el autor. Se trataba de un joven que había ingresado a un programa de postgrado, y que se proponía trabajar el tema para su tesis de Maestría. Pero además, este joven le comentó al profesor que en un inicio no sabía si enviar o no el proyecto al concurso, pero se decidió a hacerlo al enterarse por un compañero sobre la posible conformación del Jurado Evaluador. Entonces, el profesor se dirigió al Presidente del Jurado, le informó de la situación y le comunicó su decisión de inhibirse de evaluar esa propuesta. El Presidente aceptó su pedido y le agradeció el gesto.</p>
      <p>Llegado el momento de sumar los puntajes obtenidos por los proyectos y determinar cuáles serían favorecidos con el financiamiento, resultó que el del joven ex-estudiante del profesor no había alcanzado el puntaje necesario, aunque por muy estrecho margen.</p>
      <p>Al principio, el joven se mostró sorprendido por los resultados del concurso, pues confiaba en que con una evaluación positiva del profesor tendría muchas posibilidades de obtener el financiamiento. Pero luego, cuando el profesor habló con él y le contó que se había inhibido de evaluar su trabajo, se sintió “decepcionado”. No obstante, cambió de actitud una vez que el profesor le explicó el porqué de su decisión y le ofreció su apoyo para mejorar la propuesta y someterla a un nuevo concurso.</p>
      <p>*Caso ficticio</p>
    </>
  )
}

export const Contenido70 = () => {
  return (
    <>
      <h1>Módulo 7: Mentoría</h1>
      <p>En las carreras profesionales de los investigadores, la mentoría por parte de personas con más conocimiento y experiencia constituye un soporte fundamental para la formación y el desarrollo personal de quienes se inician en la actividad científica. Este módulo discute los roles y las responsabilidades que involucra esta relación, e ilustra en qué consiste –con material audiovisual- a partir de las recomendaciones y perspectivas de investigadores experimentados.</p>
      <img className={"big"} src={modulo7} alt={modulo7} />
    </>
  )
}

export const Contenido71 = () => {
  return (
    <>
      <h1>
        Objetivos
      </h1>
      <ul>
        <li>Describir los roles de los científicos más experimentados, en relación con las actividades de quienes inician sus carreras en la investigación.</li>
        <li>Definir qué es un mentor o una mentora.</li>
        <li>Ofrecer algunas pautas para trabajar de manera más eficiente con los mentores.</li>
        <li>Mostrar formas en que los investigadores jóvenes pueden obtener apoyo para sus trabajos de investigación, por parte de científicos más experimentados.</li>
      </ul>
    </>
  )
}

export const Contenido72 = () => {

  const [cap72, setcap72] = useState(-1)

  return (
    <>
      <h1>Videos</h1>
      <br />
      <CaseOption>
        <b onClick={() => { cap72 === 1 ? setcap72(-1) : setcap72(1) }} class={"yttitle"}>Videos: Módulo de Mentoría</b>
        {
          cap72 === 1
            ? (
              <div className={"videoContainer"}>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8WAQvMKWoEA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/RyW18KhACaM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            )
            : ""
        }
      </CaseOption>
      <CaseOption>
        <b onClick={() => { cap72 === 2 ? setcap72(-1) : setcap72(2) }} class={"yttitle"}>Videos: Cualidades que debe de tener un mentor</b>
        {
          cap72 === 2
            ? (
              <div className={"videoContainer"}>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8AHxIyzMeMY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IT7KCCybbSI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/SnFfHD16M-w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            )
            : ""
        }


      </CaseOption>
      <CaseOption>
        <b onClick={() => { cap72 === 3 ? setcap72(-1) : setcap72(3) }} class={"yttitle"}>Videos: Responsabilidades de un mentor</b>
        {
          cap72 === 3
            ? (
              <div className={"videoContainer"}>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HTGP8xdlvIw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/u9ztaooyA2w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            )
            : ""
        }
      </CaseOption>
      <CaseOption>
        <b onClick={() => { cap72 === 4 ? setcap72(-1) : setcap72(4) }} class={"yttitle"}>Videos: Ha tenido un mentor en investigación</b>
        {
          cap72 === 4
            ? (
              <div className={"videoContainer"}>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ew1ik4qO2_U" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3WSOHjFdIEI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kQ5B49dfzdY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BeXPg2d5cHM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            )
            : ""
        }
      </CaseOption>
      <CaseOption>
        <b onClick={() => { cap72 === 5 ? setcap72(-1) : setcap72(5) }} class={"yttitle"}>Videos: Consejos para cumplir mejor el rol de mentor</b>
        {
          cap72 === 5
            ? (
              <div className={"videoContainer"}>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HU-XIKWfT_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className={"ytvideo"}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9a0InzeVHWg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            )
            : ""
        }
      </CaseOption>
    </>
  )
}

export const Contenido73 = () => {
  return (
    <>
      <h1>Ver Clase</h1>
      <div className={"ytvideo"}>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eHsBYAa4j0k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

